/* src/color-block/index.less */
.color-block-container {
  width: 20px;
  height: 20px;
  border: 1px solid #eee;
  border-radius: 4px;
}
.color-block-transparent-background {
  background-image: url(data:image/svg+xml,%3Csvg%20%20viewBox%3D%220%200%201024%201024%22%20%20version%3D%221.1%22%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%20width%3D%2218%22%20%20height%3D%2218%22%3E%20%20%3Cpath%20%20%20%20d%3D%22M766.551919%20511.032889h255.516445v255.516444H766.551919V511.032889zM766.551919%200h255.516445v255.51903H766.551919V0zM0%20766.550626h255.516444v255.516445H0V766.550626z%20m255.516444-255.517737h255.516445v255.516444H255.516444V511.032889z%20m255.516445%20255.517737h255.516444v255.516445H511.032889V766.550626z%20m0-511.034182h255.516444v255.516445H511.032889V255.516444zM255.516444%200h255.516445v255.51903H255.516444V0zM0%20255.516444h255.516444v255.516445H0V255.516444z%22%20%20%20%20fill%3D%22%23e6e6e6%22%20%20%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
  background-color: #fff !important;
}

/* src/color-selector/index.less */
.color-picker-container {
  width: 200px;
  padding: 10px;
  color: #000;
  font-size: 13px;
  border-radius: 4px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
}
.color-picker-container .none-color-container {
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 2px 0;
  margin-bottom: 10px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-picker-container .none-color-container:hover {
  background-color: #f6f6f6;
}
.color-picker-container .color-list-title {
  margin-bottom: 5px;
}
.color-picker-container .color-list-container {
  display: flex;
  flex-wrap: wrap;
  min-height: 20px;
}
.color-picker-container .color-list-container .color-item-content {
  width: 18px;
  height: 18px;
  margin: 2px;
  border: 1px solid #f3f3f3;
  border-radius: 2px;
  cursor: pointer;
}
.color-picker-container .color-list-container .color-item-content:hover {
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  transform: scale(1.06);
}
.color-picker-container .color-list-container .color-item-content:active {
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  transform: scale(1);
}
.color-picker-container .color-list-container .color-item-content:nth-child(8) {
  margin-right: 0;
}
.color-picker-container .recommend-color-container {
  padding-bottom: 10px;
}
.color-picker-container .recent-color-container {
  padding-bottom: 10px;
}
.color-picker-container .tool-row-container {
  position: relative;
  margin: 6px 0;
}
.color-picker-container .tool-row-container:last-child {
  margin-bottom: 0;
}
.color-picker-container .tool-row-container .tool-row-content {
  display: flex;
  align-items: center;
  height: 25px;
}
.color-picker-container .tool-row-container .tool-row-icon {
  margin-right: 8px;
}
.color-picker-container .tool-row-container .tool-row-arrow {
  margin-right: 4px;
  margin-left: auto;
}
.color-picker-container .pipette-color {
  cursor: pointer;
}
.color-picker-container .pipette-color:hover {
  background-color: #f6f6f6;
}
.color-picker-container .more-color {
  position: relative;
  cursor: default;
}
.color-picker-container .more-color:hover {
  background-color: #f6f6f6;
}
.color-picker-container .more-color:hover .more-color-panel-container {
  display: block;
  visibility: visible;
}
.color-picker-container .more-color .more-color-panel-container {
  position: absolute;
  bottom: -10px;
  left: 180px;
  padding-left: 20px;
  background-color: transparent;
  border: none;
  visibility: hidden;
  transition: all 0.4s;
}
.color-picker-container .more-color .more-color-panel-container .sketch-picker {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03) !important;
}
.color-picker-container .more-color .more-color-panel-container .sketch-picker .flexbox-fix:nth-child(4) {
  display: none !important;
}
.color-picker-container .more-color .more-color-panel-left {
  left: -240px;
  padding-right: 20px;
  padding-left: 0;
}
